import React from "react";
import { Container, Row } from "reactstrap";
import getConfig from "next/config";
import CategoryContent from "./CategoryContent";
import { getValidUrl } from "helpers/utils/helpers";

const { publicRuntimeConfig } = getConfig();
const contentsUrl = `${publicRuntimeConfig.CONTENTS_URL}`;

const ProduceList = ({
  sectionClass,
  itemHeight = 200,
  categories,
  type,
  bg,
  title,
}) => {
  return (
    <Container className="mt-0 mt-xl-5">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <section
          className={sectionClass}
          style={{ width: "100%", maxWidth: 1200 }}
        >
          <h4 className="section-title mt-4 mb-4-5 text-center">
            {title || "Our Produce Categories"}
          </h4>
          <Row className="partition6">
            {categories &&
              categories.map((data, i) => {
                return (
                  <CategoryContent
                    key={i}
                    height={itemHeight}
                    img={contentsUrl + "" + data.main_produce_image01ISfile}
                    title={data.name}
                    bg={bg}
                    link={getValidUrl(
                      `/${type}/${data.numeric_id}/${data.name}`
                    )}
                  />
                );
              })}
          </Row>
        </section>
      </div>
    </Container>
  );
};

export default ProduceList;
