import React, { useEffect, useState, useContext } from "react";
import { useRouter } from "next/compat/router";
import dynamic from "next/dynamic";
import Banner from "layouts/Agri/components/Banner";
import ProduceList from "layouts/Agri/components/ProduceList";
import CommonLayout from "components/layout/common-layout";
import { AuthContext } from "helpers/auth/AuthContext";
import {
  getBanner,
  listReports,
  getSellers,
  listAdverts,
  getCategories,
} from "helpers/lib";
import useMainStore from "store/mainStore";
import useDashboardDataStore from "store/dashboardDataStore";

const AdSpaceSquareHome = dynamic(() =>
  import("./layouts/Agri/components/AdSpaceSquareHome")
);
const ReportSpace = dynamic(() =>
  import("./layouts/Agri/components/ReportSpace")
);
const AdSpace = dynamic(() => import("./layouts/Agri/components/AdSpace"));
const OurSellersSpace = dynamic(() =>
  import("./layouts/Agri/components/OurSellersSpace")
);

const Agri = ({
  categories,
  premiumAdverts,
  planAdverts,
  reports,
  sellers,
  banner,
}) => {
  const { setData, categories: storedCategories } = useMainStore();
  const { sellers: storedSellers, setSellers } = useDashboardDataStore();
  const router = useRouter();
  const authContext = useContext(AuthContext);
  const onAuth = authContext.onAuth;

  const [isAdSpaceVisible, setAdSpaceVisible] = useState(false);
  const [isReportSpaceVisible, setReportSpaceVisible] = useState(false);
  const [isOurSellersVisible, setOurSellersVisible] = useState(false);

  useEffect(() => {
    if (!storedCategories.length) {
      setData({ categories, premiumAdverts, planAdverts, reports, banner });
    }
    if (!storedSellers.length) {
      setSellers(sellers);
    }
  }, [storedCategories, setData]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const adSpaceObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setAdSpaceVisible(true);
        adSpaceObserver.disconnect();
      }
    }, observerOptions);

    const reportSpaceObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setReportSpaceVisible(true);
        reportSpaceObserver.disconnect();
      }
    }, observerOptions);

    const ourSellersObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setOurSellersVisible(true);
        ourSellersObserver.disconnect();
      }
    }, observerOptions);

    const adSpaceElement = document.querySelector("#ad-space");
    const reportSpaceElement = document.querySelector("#report-space");
    const ourSellersElement = document.querySelector("#our-sellers");

    if (adSpaceElement) adSpaceObserver.observe(adSpaceElement);
    if (reportSpaceElement) reportSpaceObserver.observe(reportSpaceElement);
    if (ourSellersElement) ourSellersObserver.observe(ourSellersElement);

    return () => {
      adSpaceObserver.disconnect();
      reportSpaceObserver.disconnect();
      ourSellersObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const auth = router.query?.auth;
    if (auth && auth === "logout") {
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      onAuth({}, false);
      router.push("/");
    }
  }, []);

  return (
    <CommonLayout title="collection" parent="home" sidebar={false}>
      <Banner banner={banner} />
      <ProduceList categories={categories} type="produce" />

      <div id="ad-space">
        {isAdSpaceVisible && (
          <AdSpaceSquareHome premiumAdverts={premiumAdverts} />
        )}
      </div>

      <div id="report-space">
        {isReportSpaceVisible && (
          <ReportSpace reports={reports} imgMaxWidth={280} size="normal" />
        )}
      </div>

      <div id="ad-space">
        {isAdSpaceVisible && <AdSpace planAdverts={planAdverts} />}
      </div>

      <div id="our-sellers">{isOurSellersVisible && <OurSellersSpace />}</div>
    </CommonLayout>
  );
};

export default Agri;

export async function getServerSideProps() {
  const categories = await getCategories();
  const premiumAdverts = await listAdverts(
    "positionISbb_agrix_adverts_positionsID",
    null,
    null,
    "1",
    "RAND()"
  );
  const planAdverts = await listAdverts(
    "positionISbb_agrix_adverts_positionsID",
    null,
    null,
    "2"
  );
  const reports = await listReports("1");
  const sellers = await getSellers();
  const banner = await getBanner();

  return {
    props: {
      categories,
      premiumAdverts,
      planAdverts,
      reports,
      sellers,
      banner,
    },
  };
}
