import { create } from "zustand";

const useDashboardDataStore = create((set) => ({
  membershipTypes: [],
  pricinglogs: [],
  sellers: [],
  loading: false,
  error: null,

  setMembershipTypes: (membershipTypes) => set({ membershipTypes }),
  setPricingLogs: (pricinglogs) => set({ pricinglogs }),
  setSellers: (sellers) => set({ sellers }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));

export default useDashboardDataStore;
