import React from "react";
import { Col, Container, Row } from "reactstrap";
import Image from "next/image";

const MasterBanner = ({ title, classes, bannerUrl, title1 }) => {
  const [one, two] = (title || "").split(".");

  return (
    (<div style={{ position: "relative" }}>
      <div
        className={`home ${classes ? classes : "text-center"}`}
        style={{
          position: "relative",
          height: "350px",
          backgroundColor: "color.hex",
        }}
      >
        <Image
          src={bannerUrl}
          alt="Banner Image"
          quality={80}
          priority
          fill
          sizes="100vw"
          style={{
            objectFit: "cover",
            objectPosition: "center"
          }} />

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))",
            zIndex: 0,
          }}
        >
          <Container
            style={{ position: "relative", marginBottom: "0px", zIndex: 1 }}
          >
            <div style={{ marginTop: "139px" }}>
              <h2 style={{ color: "#ffffff" }}>{title1 || one}</h2>
              <br />
              <h4
                style={{
                  color: "#f9f9f9",
                  fontSize: "20px",
                  fontStyle: "italic",
                  fontWeight: 700,
                  letterSpacing: ".2em",
                }}
              >
                {two}
              </h4>
            </div>
          </Container>
        </div>
      </div>
    </div>)
  );
};

export default MasterBanner;
