import React from "react";
import { Col } from "reactstrap";
import Link from "next/link";
import Image from "next/image";

const CategoryContent = ({ img, title, link, height, bg }) => {
  let styles = {
    width: "100%",
    height: `${height ?? 200}px`,
    position: "relative",
  };

  return (
    (<Col lg="4" md="6" xs="6" className="produce-list-block mb-4">
      <Link href={link}>

        <div className="collection-banner produce-card">
          <div className="img-part">
            {bg ? (
              <div style={{ ...styles, background: bg }} />
            ) : (
              <div style={{ ...styles, backgroundColor: "color.hex" }}>
                <Image
                  src={img}
                  alt={title}
                  quality={80}
                  fill
                  sizes="(max-width: 768px) 100vw, 50vw"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center"
                  }} />
              </div>
            )}
          </div>
          <div
            className="contain-banner banner-5"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 0,
              background: "rgba(0, 0, 0, .3)",
            }}
          >
            <div>
              <h4 style={{ marginBottom: 0, textAlign: "center" }}>
                {title}
              </h4>
            </div>
          </div>
        </div>

      </Link>
    </Col>)
  );
};

export default CategoryContent;
